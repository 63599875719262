import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import routes, { createRouting } from './routes';

const App = () => {
  return (
    <Router basename="/">
      {createRouting(routes)}
    </Router>
  );
};

export default App;