import React, { Suspense, Fragment } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AuthGuard from '../src/components/Guard/AuthGuard';
import MainLayout from '../src/layouts/MainLayout';

const Search = React.lazy(() => import('../src/views/Search/Search'));
const Login = React.lazy(() => import('../src/views/Login/Login'));
const Register = React.lazy(() => import('../src/views/Register/Register'));
const DeveloperProfile = React.lazy(() => import('../src/views/Profile/Developer'));
const ClientProfile = React.lazy(() => import('../src/views/Profile/Client'));

const routes = [
  {
    path: '/search',
    element: <Search />,
    guard: AuthGuard,
    layout: MainLayout,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/developerProfile',
    element: <DeveloperProfile />,
    guard: AuthGuard,
    layout: MainLayout,
  },
  {
    path: '/clientProfile',
    element: <ClientProfile />,
    layout: MainLayout,
  },
  {
    path: '*',
    element: <RedirectToSearch />,
  },
];

function RedirectToSearch() {
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate('/search');
  }, [navigate]);

  return null;
}

export const createRouting = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          return (
            <Route
              key={i}
              path={route.path}
              element={
                <Guard>
                  <Layout>{route.element}</Layout>
                </Guard>
              }
            />
          );
        })}
      </Routes>
    </Suspense>
  );
};

export default routes;