import React from 'react';
import { useNavigate } from 'react-router-dom';

const AuthGuard = ({ children }) => {
  const navigate = useNavigate();

  const isAuthenticated = () => {
    return !!localStorage.getItem('authToken');
  };

  React.useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login'); // This will navigate to the login page if user is not authenticated
    }
  }, [navigate]);

  return isAuthenticated() ? children : null;
}

export default AuthGuard;