import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.defaults.headers.common['Cache-Control'] = 'no-cache';
axiosInstance.defaults.headers.common['Pragma'] = 'no-cache';
axiosInstance.defaults.headers.common['Expires'] = '0';

axiosInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem('authToken');

  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
    config.headers['Content-Type'] = `application/json`;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem('authToken');
      window.location.href = '/login'
    }

    if (typeof error === 'string') {
      return Promise.reject({ error });
    }

    if (error.no_error) {
      return Promise.reject({ error });
    }

    return Promise.reject((error.response?.data || error.message) || 'Something went wrong');
  }
);

export default axiosInstance;
