
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/logo-white-02-up.webp';
import axiosInstance from '../../utils/axios';
const settings = ['Profile', 'Logout'];

function TopBar() {
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const user = JSON.parse(localStorage.getItem('user'))
  const userType = localStorage.getItem('userType')
  const navigate = useNavigate();



  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };



  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = async () => {
    // await axiosInstance.post(`${process.env.REACT_APP_API_URL}/api/client/clear_session`);
    localStorage.clear(); 
    navigate('/login');
    
  };

  const handleProfile = () => {
    const userType = localStorage.getItem('userType')
    navigate(`/${userType}Profile`);
  };

  return (
    <AppBar position="static" sx={{ bgcolor: '#770df7', pl: 2, pr: 2 }}>
      <Toolbar disableGutters>
        <IconButton onClick={() => navigate('/search')}>
          <img src={logo} alt="Logo" style={{ height: '30px', width: 'auto' }} />
        </IconButton>


        <Box sx={{ flexGrow: 1 }}>
        </Box>
        {userType === 'client' && 
        <Box sx={{ flexGrow: 0.1 }}>
          <Typography>Credit : <b>{user.credits || '-'}</b></Typography>

        </Box>
        }
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt="Remy Sharp" src="https://i.fbcd.co/products/resized/resized-750-500/d4c961732ba6ec52c0bbde63c9cb9e5dd6593826ee788080599f68920224e27d.jpg" />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={setting === 'Profile' ? handleProfile : handleLogout}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export default TopBar;