import React from 'react';
import Footer from '../../components/Footer/Footer';
import TopBar from '../../components/TopBar/TopBar';
import './index.css'

const MainLayout = ({ children }) => {

	return (

		<div className='main-wrapper'>
			<TopBar/>
			<div className='main-contentContainer'>
				<div className='main-content'>
					{children}
				</div>
					<Footer />
			</div>
		</div>

	);
};

export default MainLayout;
